<template>
  <div id="stepper" v-if="steps.length > 0 && currentMarker.index != undefined" class="px-4" :class="{'mobile': $vuetify.breakpoint.smAndDown}">

    <div class="video-header d-flex flex-row align-center justify-space-between ">

      <div class="flex-auto" v-if="isPlaying === false">
        <template v-if="currentMarker.index > 0 && isPlaying === false">
          <div class="d-flex flex-row align-center">
            <v-btn class="mr-2" color="primary"  @click="clickStep(steps[currentMarker.index - 1])">
              <v-icon>replay</v-icon>
            </v-btn>
            <div class="d-flex flex-column justify-start">
              <span class="label">Replay</span>
              <strong class="text">{{steps[currentMarker.index-1].title  || `Step ${parseFloat(currentMarker.index-1)}`}}</strong>
            </div>
          </div>
        </template>
      </div>

      <div class="flex-auto" v-if="isPlaying === false">
        <template v-if="currentMarker.index == 0 && isPlaying === false">
          <div class="d-flex flex-row align-center justify-end">
            <div class="d-flex flex-column justify-end">
              <span class="label text-right">Start</span>
              <strong class="text ">Say "Play" or "Start"</strong>
            </div>
            <v-btn class="ml-2 gradient" color="secondary" @click="clickStep(steps[0], 'start')">
              <v-icon>play_arrow</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-else-if="currentMarker.index > 0 && isPlaying === false">
          <div class="d-flex flex-row align-center justify-end">
            <div class="d-flex flex-column justify-end">
              <span class="label text-right">Play</span>
              <strong class="text ">{{steps[currentMarker.index].title || `Step ${parseFloat(currentMarker.index)}`}}</strong>
            </div>
            <v-btn class="ml-2" color="secondary" @click="clickStep(steps[currentMarker.index], 'start')">
              <v-icon>play_arrow</v-icon>
            </v-btn>
          </div>
        </template>
      </div>

      <div class="col-12 d-flex align-center justify-space-between" v-if="isPlaying">

        <v-btn class="mr-2" depressed color="primary" dark @click="clickStep(steps[currentMarker.index])">
          <v-icon>replay</v-icon>
        </v-btn>


        <v-progress-linear
        v-model="timeBuffer"
        v-if="$vuetify.breakpoint.mdAndUp"
        color="primary"
        :height="25"
        reactive
        ></v-progress-linear>

        <v-btn class="ml-2" depressed color="accent darken-1" dark @click="clickStep(steps[currentMarker.index+1])">
          <v-icon>skip_next</v-icon>
        </v-btn>

      </div>

    </div>


      <v-btn v-if="skipIntro && isPlaying && currentMarker.index == 0"
        color="white"
        class="skip-intro"
        @click="setSkipIntro">
        Skip Intro <v-icon>skip_next</v-icon>
      </v-btn>


  </div>
</template>

<script>


export default {
  name: "stepper",
  props: ['steps', 'currentMarker', 'isPlaying', 'skipIntro', 'timeIn', 'duration'],
  data(){
    return{
      avatarSize: 36,
      currentIndex: 0,
      timeBuffer: 0,
      durationTotal: 0,
      stepTotal: 0,
    }
  },
  watch:{
    timeIn:function(newV,oldV){
      if(newV.timeupdate !== undefined){
        // // process.env.NODE_ENV === "development" ? console.log( 'timeIn', newV.timeupdate, oldV.timeupdate, this.time ) : null;
        // let cleanSeconds =  Math.floor(newV.timeupdate|0);
        // let total = 0;
        // let percent = 0;
        // process.env.NODE_ENV === "development" ? console.log( this.currentMarker.index+1 < this.steps.length) : null;
        // if(this.currentMarker.index+1 < this.steps.length){
        //   total = this.steps[this.currentMarker.index+1].timestamp - this.steps[this.currentMarker.index].timestamp;
        //   // console.log('total', cleanSeconds, total, (((cleanSeconds-this.steps[this.currentMarker.index+1].timestamp) + total) / total)*100 );
        //   percent = (((cleanSeconds-this.steps[this.currentMarker.index+1].timestamp) + total) / total)*100
        // }
        // else{
        //   // percent = ((cleanSeconds)/(this.durationTotal))*100
        //   total = this.durationTotal - this.steps[this.currentMarker.index].timestamp;
        //   percent = (((cleanSeconds-this.durationTotal) + total) / total)*100
        //   console.log('total', cleanSeconds, this.durationTotal, total, (((cleanSeconds-this.durationTotal) + total) / total)*100);
        // }
        let remainingTime = this.currentMarker.index+1 < this.steps.length ? this.steps[this.currentMarker.index+1].timestamp : this.duration;
        // $stepPercentLeft(currentIndex, stepLength, seconds, currentTimestamp, nextTimestamp, durationTotal)
        let percent = this.$stepPercentLeft(this.currentMarker.index,this.steps.length,newV.timeupdate+1,this.steps[this.currentMarker.index].timestamp, remainingTime ,this.duration);
        this.timeBuffer = this.steps[this.currentMarker.index].timestamp <= this.currentMarker.step.time ? percent :100
      }
    },
    // currentMarker:{
    //   handler: function (val, oldVal) {
    //     process.env.NODE_ENV === "development" ? console.log( 'val from video', val ) : null;
    //     if(this.currentMarker.index+1 < this.steps.length){
    //       this.stepTotal = this.steps[this.currentMarker.index+1].timestamp;
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    setIndex(index){
      this.currentIndex = index;
      return index
    },
    setSkipIntro(){
      this.$emit('skipIntro', true);
    },
    clickStep(marker, start){
      let data = []
      data.element = false;
      data.step = marker;
      data.start = start != undefined ? true : false;
      this.$emit('onStepClicked', data)
    },
    startPlaying(){
      this.$emit('startPlaying', true)
    }
  },
  mounted(){
    this.durationTotal = this.$moment.duration(this.duration).asSeconds()
  }
};
// <!-- back buttons -->
// <div class="col-4 pa-0">
//   <template v-if="isPlaying !== false">
//     <template v-if="currentMarker.index > 0">
//       <div class="d-flex flex-row align-center">
//         <v-btn class="mr-2" color="primary"  @click="clickStep(steps[currentMarker.index - 1])">
//           <v-icon>arrow_back</v-icon>
//         </v-btn>
//         <div class="d-flex flex-column justify-start">
//           <span class="label">Previous - STILL PLAYING</span>
//           <strong class="text">{{steps[currentMarker.index].title  || `Step ${parseFloat(currentMarker.index)}`}}</strong>
//         </div>
//       </div>
//     </template>
//     <template v-else>
//       <div class="d-flex flex-row align-center">
//         <v-btn class="mr-2" color="primary"  @click="clickStep(steps[currentMarker.index - 1])">
//           <v-icon>replay</v-icon>
//         </v-btn>
//         <div class="d-flex flex-column justify-start">
//           <span class="label">Start Over</span>
//           <strong class="text">{{steps[currentMarker.index].title  || `Step ${parseFloat(currentMarker.index+1)}`}}</strong>
//         </div>
//       </div>
//     </template>
//   </template>
//
//   <!-- not playing -->
//   <template v-else>
//     <template v-if="currentMarker.index == 0 && !isPlaying">
//       <div class="d-flex flex-row align-stretch">
//         <v-icon class="mr-2">tag_faces</v-icon>
//         <div class="d-flex flex-column justify-start">
//           <span class="label ">Start</span>
//           <strong class="text ">Say "Play" or "Start"</strong>
//         </div>
//       </div>
//     </template>
//     <template v-else>
//       <div class="d-flex flex-row align-center">
//         <v-btn class="mr-2" color="primary"  @click="clickStep(steps[currentMarker.index - 2])">
//           <v-icon>arrow_back</v-icon>
//         </v-btn>
//         <div class="d-flex flex-column justify-start">
//           <span class="label">Previous -- NOT PLAYING</span>
//           <strong class="text">{{steps[currentMarker.index-2].title  || `Step ${parseFloat(currentMarker.index-1)}`}}</strong>
//         </div>
//       </div>
//     </template>
//   </template>
//
//
// </div>
//
//
// <div class="col-4 pa-0">
//   <template v-if="currentMarker.index != 0 && !isPlaying">
//     <div class="d-flex flex-row align-center justify-center">
//       <v-btn text class="mt-1" color="primary"  @click="clickStep(steps[currentMarker.index - 1])">
//         <v-icon>replay</v-icon>
//         <div class="d-flex flex-column justify-start">
//           <span class="label text-right">Replay</span>
//           <strong class="text text-right">{{steps[currentMarker.index].title  || `Step ${parseFloat(currentMarker.index)}`}}</strong>
//         </div>
//       </v-btn>
//     </div>
//   </template>
// </div>
//
//
// <!-- forward buttons -->
// <div class="col-4 pa-0">
//   <template v-if="currentMarker.index == 0 && isPlaying === false">
//       <div class="d-flex flex-row align-center justify-end">
//         <div class="d-flex flex-column justify-end">
//           <span class="label text-right">Start</span>
//           <strong class="text text-right">{{steps[0].title || `Step ${parseFloat(currentMarker.index)+1}`}}</strong>
//         </div>
//         <v-btn class="ml-2 gradient" color="secondary" @click="clickStep(steps[0])">
//           <v-icon>play_arrow</v-icon>
//         </v-btn>
//       </div>
//   </template>
//   <template v-else-if="currentMarker.index < steps.length && isPlaying === false">
//     <div class="d-flex flex-row align-stretch justify-end">
//       <div class="d-flex flex-column justify-end">
//         <span class="label text-right">Next</span>
//         <strong class="text text-right">{{steps[currentMarker.index+1].title  || `Step ${parseFloat(currentMarker.index)+2}`}}</strong>
//       </div>
//       <v-btn class="ml-2" color="secondary" @click="clickStep(steps[currentMarker.index+1])">
//         <v-icon>arrow_forward</v-icon>
//       </v-btn>
//     </div>
//   </template>
//   <template v-else-if="currentMarker.index < steps.length && isPlaying">
//     <div class="d-flex flex-row align-stretch justify-end">
//       <div class="d-flex flex-column justify-end">
//         <span class="label text-right">Next</span>
//         <strong class="text text-right">{{steps[currentMarker.index].title  || `Step ${parseFloat(currentMarker.index)+1}`}}</strong>
//       </div>
//       <v-btn class="ml-2" color="secondary" @click="clickStep(steps[currentMarker.index])">
//         <v-icon>arrow_forward</v-icon>
//       </v-btn>
//     </div>
//   </template>
//   <template v-else>
//     <div class="d-flex flex-row align-stretch justify-end">
//       <div class="d-flex flex-column justify-end">
//         <span class="label text-right">Done</span>
//         <strong class="text text-right">That's it</strong>
//       </div>
//       <v-icon>tag_faces</v-icon>
//     </div>
//   </template>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
%gradientBG{
  background: #FEAC5E!important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#stepper{
  position: relative;
  .video-header{
    height: 65px;
    .label{
      opacity: .5;
      font-size: .8rem;
    }
    .text{
      line-height: 1rem;
    }
  }

  header{
    .layout{padding: 5px;}
    .stats{
      margin-right: 10px;
      font-size: 20px;
      font-weight: bold;
      color: white;
      .step-number{
        font-size: 150%;
      }
      .slash{
        opacity: .5;
        padding: 0 2px;
      }
      .step-total{
        opacity: .75;
        font-size: 75%;
      }
    }
  }

  .v-progress-linear__background{
    background-color: #bbb!important;
  }
  .v-progress-linear__determinate{
    @extend %gradientBG;
  }

  .skip-intro{
    position: absolute;
    z-index: 9999999;
    top:-90px;
    right: 5%;
  }

  .order-first{
    order:0;
  }
  .order-last{
    order: 10;
  }
  .wizard{
    padding: 10px;
    .v-card{
      padding: 10px;
    }
    .v-avatar{
      margin: 0 8px 5px 5px;
      &:hover{
        cursor: pointer;
      }
    }
  }

}
</style>
